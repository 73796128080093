import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setWarning } from "../../../state/uiSlice";
import useSynthetixHandlerStorage from "../contracts/use-synthetix-handler-storage";
import { useEthers } from "@usedapp/core";
import { ScaledNumber } from "scaled-number";

const useExitLoad = (
  leveragedToken: string,
  amount: ScaledNumber
): ScaledNumber | null => {
  const dispatch = useDispatch();
  const contract = useSynthetixHandlerStorage();
  const { account } = useEthers();
  const [value, setValue] = useState<ScaledNumber | null>(null);

  useEffect(() => {
    const getValue = async () => {
      if (!contract) return;
      if (!account) return;
      if (!leveragedToken) return;

      try {
        const value_ = await contract.decayingRedemptionFeeFromLt(
          leveragedToken,
          account,
          amount.value
        );
        setValue(new ScaledNumber(value_));
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching exit load"));
      }
    };

    getValue();
  }, [dispatch, contract, leveragedToken, account, amount.value]);

  return value;
};

export default useExitLoad;
