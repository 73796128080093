import { ScaledNumber } from "scaled-number";
import { LeveragedTokenDataType } from "../web3/views/use-leveraged-token-data";

const getLeverageRange = (
  leveragedTokenData: LeveragedTokenDataType | undefined
): {
  upperLeverage: ScaledNumber | null;
  lowerLeverage: ScaledNumber | null;
} => {
  if (!leveragedTokenData) return { upperLeverage: null, lowerLeverage: null };

  const { targetLeverage, rebalanceThreshold } = leveragedTokenData;

  if (leveragedTokenData.isLong) {
    return {
      upperLeverage: targetLeverage
        .sub(rebalanceThreshold)
        .div(ScaledNumber.fromUnscaled(1).sub(rebalanceThreshold)),
      lowerLeverage: targetLeverage
        .add(rebalanceThreshold)
        .div(ScaledNumber.fromUnscaled(1).add(rebalanceThreshold)),
    };
  }

  return {
    upperLeverage: targetLeverage
      .add(rebalanceThreshold)
      .div(ScaledNumber.fromUnscaled(1).sub(rebalanceThreshold)),
    lowerLeverage: targetLeverage
      .sub(rebalanceThreshold)
      .div(ScaledNumber.fromUnscaled(1).add(rebalanceThreshold)),
  };
};

export default getLeverageRange;
