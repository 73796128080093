import { ScaledNumber } from "scaled-number";
import useVelodromePool from "../contracts/use-velodrome-pool";
import { useEffect, useState } from "react";
import useBlock from "../utils/use-block";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import { setWarning } from "../../../state/uiSlice";
import useMulticall from "./use-multicall";

const WETH_USDC_POOL = "0x0493Bf8b6DBB159Ce2Db2E0E8403E753Abd1235b";
const VELO_VELODROME_POOL = "0x58e6433A6903886E440Ddf519eCC573c4046a6b2";

const useVeloPrice = (): ScaledNumber | null => {
  const dispatch = useDispatch();
  const veloPool = useVelodromePool(VELO_VELODROME_POOL);
  const usdcPool = useVelodromePool(WETH_USDC_POOL);
  const supportedChain = useSupportedChain();
  const [veloPrice, setVeloPrice] = useState<ScaledNumber | null>(null);
  const block = useBlock();
  const multicall = useMulticall();

  useEffect(() => {
    const getVeloPrice = async () => {
      if (!veloPool) return;
      if (!usdcPool) return;
      if (!supportedChain) return;

      try {
        const veloPoolReserves = await veloPool.getReserves();
        const usdcPoolReserves = await usdcPool.getReserves();
        const ethVeloPoolReserves = new ScaledNumber(veloPoolReserves[0]);
        const veloVeloPoolReserves = new ScaledNumber(veloPoolReserves[1]);
        const veloPriceInEth = ethVeloPoolReserves.div(veloVeloPoolReserves);
        const ethUsdcPoolReserves = new ScaledNumber(usdcPoolReserves[0]);
        const usdcUsdcPoolReserves = new ScaledNumber(usdcPoolReserves[1], 6);
        const ethPriceInUsdc = usdcUsdcPoolReserves.div(ethUsdcPoolReserves);
        const veloPriceInUsdc = veloPriceInEth.mul(ethPriceInUsdc);

        setVeloPrice(veloPriceInUsdc);
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching VELO price"));
      }
    };

    getVeloPrice();
  }, [block, veloPool, supportedChain, dispatch, usdcPool, multicall]);

  return veloPrice;
};

export default useVeloPrice;
