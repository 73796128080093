import styled from "styled-components";
import susdIcon from "../../assets/ui/susd.svg";
import { ScaledNumber } from "scaled-number";
import getLeveragedTokenName from "../../app/helpers/get-leveraged-token-name";
import getLeveragedTokenIcon from "../../app/helpers/get-leveraged-token-icon";
import getLeveragedTokenSymbol from "../../app/helpers/get-leveraged-token-symbol";

const StyledLeveragedTokenAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.3rem;
`;

const LargeValue = styled.div`
  font-size: 2.4rem;
  font-weight: 400;
  margin-left: 0.8rem;
`;

const SmallValue = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--sub);
  margin-right: 0.6rem;
`;

const SusdIcon = styled.img`
  height: 1.4rem;
`;

const LeveragedTokenIcon = styled.img`
  height: 5rem;
`;

interface Props {
  assetId: string;
  leverage: number;
  isLong: boolean;
  expectedOut: ScaledNumber | null;
  exchangeRate: ScaledNumber | null;
}

const LeveragedTokenAmount = ({
  assetId,
  leverage,
  isLong,
  expectedOut,
  exchangeRate,
}: Props) => {
  return (
    <StyledLeveragedTokenAmount>
      <Section>
        <LeveragedTokenIcon
          src={getLeveragedTokenIcon(
            getLeveragedTokenSymbol(assetId, leverage, isLong)
          )}
        />
        <LargeValue>
          {getLeveragedTokenName(assetId, leverage, isLong)}
        </LargeValue>
      </Section>
      <Column>
        <LargeValue>
          {expectedOut ? expectedOut.toCryptoString() : "---"}
        </LargeValue>
        <Section>
          <SmallValue>
            {expectedOut && exchangeRate
              ? expectedOut.mul(exchangeRate).toCryptoString()
              : "---"}
          </SmallValue>
          <SusdIcon src={susdIcon} alt="sUSD Icon" />
        </Section>
      </Column>
    </StyledLeveragedTokenAmount>
  );
};

export default LeveragedTokenAmount;
