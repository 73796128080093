import styled from "styled-components";
import { GlossFrame } from "../styles/Frames";
import ComingSoon from "./ComingSoon";

const Container = styled.div<{ $toEdge: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $toEdge }) =>
    $toEdge ? "space-between" : "space-evenly"};
  width: 100%;
  height: 4.8rem;
`;

const RadioButton = styled.button<{ $comingSoon?: boolean }>`
  cursor: ${({ $comingSoon }) => ($comingSoon ? "not-allowed" : "pointer")};
`;

const StyledRadio = styled(GlossFrame)`
  height: 4.8rem;
  aspect-ratio: 1;
  border-radius: 50%;
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface RadioOption {
  label: string;
  value: string;
  comingSoon?: boolean;
}

interface Props {
  active: RadioOption;
  setActive: (option: RadioOption) => void;
  options: RadioOption[];
}

const Radio = ({ active, setActive, options }: Props) => {
  return (
    <Container $toEdge={options.length > 3}>
      {options.map((option: RadioOption) => (
        <RadioButton key={option.value} disabled={option.comingSoon}>
          <ComingSoon comingSoon={option.comingSoon}>
            <StyledRadio
              $active={active.value === option.value}
              onClick={() => setActive(option)}
            >
              {option.label}
            </StyledRadio>
          </ComingSoon>
        </RadioButton>
      ))}
    </Container>
  );
};

export default Radio;
