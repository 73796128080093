import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import { useDispatch } from "react-redux";
import { setWarning } from "../../../state/uiSlice";

const ENDPOINT = `https://coins.llama.fi/prices/current/`;

const throwError = (address_: string, message: string) => {
  throw new Error(`Error getting ${address_} price: ${message}`);
};

const useTokenPrice = (address: string | undefined): ScaledNumber | null => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState<ScaledNumber | null>(null);

  useEffect(() => {
    const getAmount = async () => {
      if (!address) return;

      try {
        const id = `optimism:${address}`;
        const response = await fetch(`${ENDPOINT}${id}`);
        const data = await response.json();
        if (!data) throwError(address, "no data");
        if (!data.coins) throwError(address, "no data.coins");
        const priceData = data.coins[id];
        if (!priceData) throwError(address, "no priceData");
        const price = priceData.price;
        if (!price) throwError(address, "no price");
        if (price === 0) throwError(address, "price is 0");

        setAmount(ScaledNumber.fromUnscaled(price.toString()));
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning(`Error fetching ${address} price`));
      }
    };

    getAmount();
  }, [dispatch, address]);

  return amount;
};

export default useTokenPrice;
