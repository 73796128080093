import useChainId from "./use-chain-id";

const SUPPORTED_CHAINS = [10];

const useSupportedChain = (): boolean => {
  const chainId = useChainId();
  if (chainId === null) return false;
  return SUPPORTED_CHAINS.includes(chainId);
};

export default useSupportedChain;
