import { useEthers } from "@usedapp/core";
import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import useLocker from "../contracts/use-locker";
import useBlock from "../utils/use-block";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import { setWarning } from "../../../state/uiSlice";
import useMulticall from "./use-multicall";

interface LockerDataType {
  isShutdown: boolean;
  totalRewards: ScaledNumber;
  unlockTime: Date;
  userLocked: ScaledNumber;
  totalLocked: ScaledNumber;
  claimable: ScaledNumber;
}

const useLockerData = () => {
  const dispatch = useDispatch();
  const { account } = useEthers();
  const lockerContract = useLocker();
  const supportedChain = useSupportedChain();
  const [lockerData, setLockerData] = useState<LockerDataType | null>(null);
  const block = useBlock();
  const multicall = useMulticall();

  useEffect(() => {
    if (!account) return;
    if (!lockerContract) return;
    if (!supportedChain) return;

    const getLockerData = async () => {
      try {
        const [
          isShutdown,
          totalRewards,
          unlockTime,
          userLocked,
          totalLocked,
          claimable,
        ] = await multicall([
          lockerContract.multi.isShutdown(),
          lockerContract.multi.totalRewards(),
          lockerContract.multi.unlockTime(account),
          lockerContract.multi.balanceOf(account),
          lockerContract.multi.totalStaked(),
          lockerContract.multi.claimable(account),
        ]);

        setLockerData({
          isShutdown,
          totalRewards: new ScaledNumber(totalRewards),
          unlockTime: new Date(Number(unlockTime) * 1000),
          userLocked: new ScaledNumber(userLocked),
          totalLocked: new ScaledNumber(totalLocked),
          claimable: new ScaledNumber(claimable),
        });
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching locker data"));
      }
    };

    getLockerData();
  }, [account, block, lockerContract, supportedChain, dispatch, multicall]);

  return lockerData;
};

export default useLockerData;
