import { TENDERLY } from "../../constants/endpoints";

interface TenderlySimulationParams {
  from: string;
  to: string;
  data: string;
}

const tenderlySimulation = async (params: TenderlySimulationParams[]) => {
  const response = await fetch(TENDERLY, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: 0,
      jsonrpc: "2.0",
      method: "tenderly_simulateBundle",
      params: [params, "latest"],
    }),
  });

  return await response.json();
};

export default tenderlySimulation;
