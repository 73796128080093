import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ScaledNumber } from "scaled-number";
import { setWarning } from "../../../state/uiSlice";
import useSynthetixHandlerStorage from "../contracts/use-synthetix-handler-storage";

const useDecayingFeePeriod = (): number | null => {
  const dispatch = useDispatch();
  const contract = useSynthetixHandlerStorage();
  const [amount, setAmount] = useState<number | null>(null);

  useEffect(() => {
    const getValue = async () => {
      if (!contract) return;

      try {
        const value_ = await contract.redemptionFeeDuration();
        setAmount(new ScaledNumber(value_, 0).toNumber() / 60);
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching decaying fee period"));
      }
    };

    getValue();
  }, [dispatch, contract]);

  return amount;
};

export default useDecayingFeePeriod;
