import { ScaledNumber } from "scaled-number";

const ROUND_PERCENTAGE = 0.001; // 0.1%

export const getRoundedScaledNumber = (
  amount: string,
  balance: ScaledNumber | null
): ScaledNumber => {
  if (!amount) return ScaledNumber.fromUnscaled("0");
  if (amount === "0") return ScaledNumber.fromUnscaled("0");
  if (!balance) return ScaledNumber.fromUnscaled("0");
  if (balance.isZero()) return ScaledNumber.fromUnscaled("0");

  const minBalance = balance.mul(1 - ROUND_PERCENTAGE);
  const amountScaled = ScaledNumber.fromUnscaled(amount, balance.decimals);
  if (amountScaled.gt(minBalance)) return balance;
  return amountScaled;
};

export default getRoundedScaledNumber;
