import styled from "styled-components";
import Tooltip from "./Tooltip";

import externalIcon from "../assets/ui/external.svg";

const StyledInfoLine = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
`;

interface StyleProps {
  $primary?: boolean;
  $error?: boolean;
  $success?: boolean;
}

const Label = styled.div<StyleProps>`
  font-size: ${(props) => (props.$primary ? "2.4rem" : "1.5rem")};
  font-weight: 400;

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

const Value = styled.div<StyleProps>`
  font-weight: 500;

  font-size: ${(props) => (props.$primary ? "2.4rem" : "1.5rem")};
  color: ${(props) =>
    props.$error
      ? "var(--error)"
      : props.$success
      ? "var(--success)"
      : "var(--main)"};

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

const ValueLink = styled.a<StyleProps>`
  font-weight: 500;
  text-decoration: underline;

  font-size: ${(props) => (props.$primary ? "2.4rem" : "1.5rem")};
  color: ${(props) =>
    props.$error
      ? "var(--error)"
      : props.$success
      ? "var(--success)"
      : "var(--main)"};

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

const Unit = styled.div<StyleProps>`
  font-size: ${(props) => (props.$primary ? "2.4rem" : "1.5rem")};
  font-weight: 400;
  color: var(--sub);
  margin-left: 0.8rem;

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

const External = styled.img`
  width: 1.5rem;
  margin-left: 0.8rem;

  @media (max-width: 900px) {
    width: 1.4rem;
  }
`;

interface Props {
  label: string;
  tooltip?: string;
  value: string;
  unit?: string;
  primary?: boolean;
  error?: boolean;
  success?: boolean;
  link?: string;
}

const InfoLine = ({
  label,
  tooltip,
  value,
  unit,
  primary,
  error,
  success,
  link,
}: Props) => {
  return (
    <StyledInfoLine>
      <Section>
        <Label $primary={primary}>{label}</Label>
        {tooltip && <Tooltip>{tooltip}</Tooltip>}
      </Section>
      <Section>
        {!link && (
          <Value $error={error} $success={success} $primary={primary}>
            {value}
          </Value>
        )}
        {link && (
          <ValueLink
            $error={error}
            $primary={primary}
            $success={success}
            href={link}
            target="_blank"
          >
            {value}
            {link && <External src={externalIcon} alt="external" />}
          </ValueLink>
        )}
        {unit && !link && <Unit $primary={primary}>{unit}</Unit>}
      </Section>
    </StyledInfoLine>
  );
};

export default InfoLine;
