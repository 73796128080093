import styled from "styled-components";
import useLeveragedTokenData from "../../app/web3/views/use-leveraged-token-data";
import LeveragedTokenData from "./LeveragedTokenData";
import PortfolioReturns from "./PortfolioReturns";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Paragraph, Header4 } from "../../styles/content";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { MINT_PATH } from "../../app/constants/paths";
import OpIncentivesBanner from "../../components/OpIncentivesBanner";

const StyledPortfolioPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 4rem;
  max-width: 107rem;

  @media (max-width: 900px) {
    padding: 2rem;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
`;

const LeveragedTokens = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
  grid-gap: 2.4rem;
  width: 100%;
  margin-top: 4rem;
  justify-content: center;
`;

const EmptyStateContainer = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyState = styled.div`
  margin: 4rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.4rem;
  justify-items: center;

  * {
    margin: 0;
  }
`;

const PortfolioPage = () => {
  const navigate = useNavigate();
  const leveragedTokenDataSource = useLeveragedTokenData();
  const leveragedTokenData = leveragedTokenDataSource?.filter(
    (data) =>
      data.isActive && !data.exchangeRate.isZero() && !data.userBalance.isZero()
  );

  return (
    <StyledPortfolioPage>
      <OpIncentivesBanner />
      <PortfolioReturns leveragedTokens={leveragedTokenData} />
      {leveragedTokenData && leveragedTokenData.length === 0 && (
        <EmptyStateContainer>
          <EmptyState>
            <Header4>No Leveraged Tokens</Header4>
            <Paragraph>
              Mint a Leveraged Token and it will show up here in your portfolio
            </Paragraph>
            <Button action={() => navigate(`/${MINT_PATH}`)} primary>
              Mint a Leveraged Token
            </Button>
          </EmptyState>
        </EmptyStateContainer>
      )}
      {!leveragedTokenData && (
        <LoadingContainer>
          <LoadingSpinner show />
        </LoadingContainer>
      )}
      {leveragedTokenData && (
        <LeveragedTokens>
          {leveragedTokenData.map((data) => {
            return <LeveragedTokenData key={data.addr} data={data} />;
          })}
        </LeveragedTokens>
      )}
    </StyledPortfolioPage>
  );
};

export default PortfolioPage;
