import useContract from "../utils/use-contract";
import abi from "../abis/multicall-abi.json";
import { MULTICALL_ADDRESS } from "../../constants/addresses";
import { BytesLike } from "ethers/lib/utils";
import { useCallback } from "react";

export interface MulticallType {
  target: string;
  calldata: string;
  decodeResult: (data: BytesLike) => any;
}

const useMulticall = (): ((calls: MulticallType[]) => Promise<any>) => {
  const contract = useContract(MULTICALL_ADDRESS, abi);

  const multicall = useCallback(
    async (calls: MulticallType[]) => {
      if (!contract) return;
      const response = await contract.callStatic.aggregate(
        calls.map((call) => [call.target, call.calldata])
      );
      return response.returnData.map(
        (data: any, i: number) => calls[i].decodeResult(data)[0]
      );
    },
    [contract]
  );

  return multicall;
};

export default useMulticall;
