import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import useParameterProvider from "../contracts/use-parameter-provider";
import { setWarning } from "../../../state/uiSlice";

const useRedemptionFee = (): ScaledNumber | null => {
  const dispatch = useDispatch();
  const supportedChain = useSupportedChain();
  const [redemptionFee, setRedemptionFee] = useState<ScaledNumber | null>(null);
  const paramProvider = useParameterProvider();

  useEffect(() => {
    if (!paramProvider) return;
    if (!supportedChain) return;

    const getAmount = async () => {
      try {
        const redemptionFee_ = await paramProvider.redemptionFee();

        setRedemptionFee(new ScaledNumber(redemptionFee_));
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching redemption fee"));
      }
    };

    getAmount();
  }, [supportedChain, paramProvider, dispatch]);

  return redemptionFee;
};

export default useRedemptionFee;
