import abi from "../abis/synthetix-market-abi.json";
import { useEffect, useState } from "react";
import { Contract, providers } from "ethers";
import useSynthetixHandler from "./use-synthetix-handler";
import { useEthers } from "@usedapp/core";
import { useDispatch } from "react-redux";
import { setWarning } from "../../../state/uiSlice";

const useSynthetixMarket = (targetAsset: string | null) => {
  const dispatch = useDispatch();
  const synthetixHandler = useSynthetixHandler();
  const { library } = useEthers();

  const [contract, setContract] = useState<Contract | null>(null);

  const hasLibrary = !!library;
  useEffect(() => {
    const getContract = async () => {
      if (!library) return;
      if (!(library instanceof providers.JsonRpcProvider)) return;
      if (!targetAsset) return;
      if (!synthetixHandler) return;

      try {
        const marketAddress = await synthetixHandler.market(targetAsset);

        setContract(new Contract(marketAddress, abi, library.getSigner()));
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching Synthetix market"));
      }
    };

    getContract();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetAsset, synthetixHandler, hasLibrary, dispatch]);

  return contract;
};

export default useSynthetixMarket;
