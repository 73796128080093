import { ScaledNumber } from "scaled-number";
import useDefillamaApr from "./use-defillama-apr";

const POOL_ID = "151145a1-417d-4a18-a4c5-c28f4cad2cfa";

const useStakerApr = (): ScaledNumber | null => {
  return useDefillamaApr(POOL_ID, "Staker");
};

export default useStakerApr;
