import { useEffect, useState } from "react";
import useLeveragedTokenHelper from "../contracts/use-leveraged-token-helper";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import { useEthers } from "@usedapp/core";
import { ScaledNumber } from "scaled-number";
import useBlock from "../utils/use-block";
import { setWarning } from "../../../state/uiSlice";
import { SUPPORTED_ASSETS } from "../../constants/supported-assets";

const TOKENS_PER_PAGE = 20;

export interface LeveragedTokenDataType {
  addr: string;
  symbol: string;
  totalSupply: ScaledNumber;
  targetAsset: string;
  targetLeverage: ScaledNumber;
  isLong: boolean;
  isActive: boolean;
  rebalanceThreshold: ScaledNumber;
  exchangeRate: ScaledNumber;
  canRebalance: boolean;
  hasPendingLeverageUpdate: boolean;
  remainingMargin: ScaledNumber;
  leverage: ScaledNumber;
  assetPrice: ScaledNumber;
  userBalance: ScaledNumber;
}

const useLeveragedTokenData = () => {
  const dispatch = useDispatch();
  const supportedChain = useSupportedChain();
  const helper = useLeveragedTokenHelper();
  const { account } = useEthers();
  const block = useBlock();

  const [leveragedTokenData, setLeveragedTokenData] = useState<
    LeveragedTokenDataType[] | null
  >(null);

  useEffect(() => {
    if (!helper) return;
    if (!supportedChain) return;
    if (!account) return;

    const getLeveragedTokenData = async () => {
      try {
        const leveragedTokenData: LeveragedTokenDataType[] = [];

        let i = 0;
        while (true) {
          const fn = "leveragedTokenData(address,uint256,uint256)";
          const res = await helper[fn](account, i, TOKENS_PER_PAGE);
          const data = res.map((data: any) => {
            return {
              addr: data.addr,
              symbol: data.symbol,
              totalSupply: new ScaledNumber(data.totalSupply),
              targetAsset: data.targetAsset,
              targetLeverage: new ScaledNumber(data.targetLeverage),
              isLong: data.isLong,
              isActive: data.isActive,
              rebalanceThreshold: new ScaledNumber(data.rebalanceThreshold),
              exchangeRate: new ScaledNumber(data.exchangeRate),
              canRebalance: data.canRebalance,
              hasPendingLeverageUpdate: data.hasPendingLeverageUpdate,
              remainingMargin: new ScaledNumber(data.remainingMargin),
              leverage: new ScaledNumber(data.leverage),
              assetPrice: new ScaledNumber(data.assetPrice),
              userBalance: new ScaledNumber(data.userBalance),
            };
          });
          leveragedTokenData.push(...data);
          if (data.length < TOKENS_PER_PAGE) break;
          i += TOKENS_PER_PAGE;
        }

        setLeveragedTokenData(
          leveragedTokenData.filter((data: LeveragedTokenDataType) =>
            SUPPORTED_ASSETS.includes(data.targetAsset)
          )
        );
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching leveraged token data"));
      }
    };

    getLeveragedTokenData();
  }, [helper, dispatch, supportedChain, account, block]);

  return leveragedTokenData;
};

export default useLeveragedTokenData;
