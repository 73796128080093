import styled from "styled-components";
import Tooltip from "./Tooltip";
import { DEFILLAMA_API_DOCS } from "../app/constants/urls";

const StyledAprTooltip = styled.div`
  color: var(--main);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
`;

const Link = styled.a`
  color: var(--main);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
  text-decoration: underline;
  cursor: pointer;
`;

interface Props {
  codeLink: string;
  success?: boolean;
}

const AprTooltip = ({ success, codeLink }: Props) => {
  return (
    <Tooltip success={success}>
      <StyledAprTooltip>
        {"TLX uses the "}
        <Link href={DEFILLAMA_API_DOCS} target="_blank">
          DefiLlama API
        </Link>
        {" to calculate APR. This is open source on "}
        <Link href={codeLink} target="_blank">
          their GitHub
        </Link>
        {". The APR is variable and subject to change."}
      </StyledAprTooltip>
    </Tooltip>
  );
};

export default AprTooltip;
