import styled from "styled-components";

import Button from "../../components/Button";
import { CardFrame } from "../../styles/Frames";
import InfoSection from "../../components/InfoSection";
import useVestingData from "../../app/web3/views/use-vesting-data";
import useVesting from "../../app/web3/contracts/use-vesting";
import { useDispatch } from "react-redux";
import { setError } from "../../state/uiSlice";
import { useParams } from "react-router-dom";
import { useEthers } from "@usedapp/core";

const StyledVestingPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 4rem;

  @media (max-width: 900px) {
    padding: 2rem;
  }
`;

const Container = styled(CardFrame)`
  display: flex;
  flex-direction: column;
  padding: 4rem;

  @media (max-width: 900px) {
    padding: 2rem;
    width: 100%;
  }
`;

const Header = styled.h1`
  font-size: 3.2rem;
  font-weight: 400;
  margin-bottom: 0.8rem;
  margin-bottom: 2.4rem;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 2.4rem;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 2.4rem;
`;

const VestingPage = () => {
  const addressOverride = useParams().addressOverride;
  const dispatch = useDispatch();
  const vestingData = useVestingData(addressOverride);
  const vestingContract = useVesting();
  const { account } = useEthers();

  const isDelegateClaim = addressOverride && addressOverride !== account;

  return (
    <StyledVestingPage>
      <Container>
        <Header>Claim Vested TLX Tokens</Header>

        <InfoSection
          lines={[
            {
              label: "Allocated TLX",
              tooltip:
                "The amount of tokens that were allocated to you for vesting",
              value: vestingData
                ? vestingData.allocated.toCryptoString()
                : "---",
              unit: "TLX",
            },
            {
              label: "Vested TLX",
              tooltip: "The amount of tokens that you have vested",
              value: vestingData ? vestingData.vested.toCryptoString() : "---",
              unit: "TLX",
            },
            {
              label: "Vesting TLX",
              tooltip: "The amount of tokens that you have vesting",
              value: vestingData ? vestingData.vesting.toCryptoString() : "---",
              unit: "TLX",
            },
            {
              label: "Claimed TLX",
              tooltip: "The amount of tokens that you have claimed",
              value: vestingData ? vestingData.claimed.toCryptoString() : "---",
              unit: "TLX",
            },
            {
              label: "Claimable TLX",
              tooltip: "The amount of tokens you have claimable",
              value: vestingData
                ? vestingData.claimable.toCryptoString()
                : "---",
              unit: "TLX",
            },
          ]}
        />

        <ButtonContainer>
          <Button
            web3
            wide
            primary
            action={async () => {
              if (!vestingContract) return;
              try {
                if (isDelegateClaim) {
                  const tx = await vestingContract["claim(address,address)"](
                    addressOverride,
                    account
                  );
                  await tx.wait();
                } else {
                  const tx = await vestingContract["claim()"]();
                  await tx.wait();
                }
              } catch (e: any) {
                dispatch(
                  setError({
                    message: e.message,
                    source: "Vesting/Claim",
                  })
                );
              }
            }}
            loading={!vestingData}
            disabled={
              !vestingData?.claimable.toNumber() ||
              (!!vestingData && !!isDelegateClaim && !vestingData.isDelegate)
            }
          >
            {`Claim ${vestingData?.claimable.toCryptoString()} TLX`}
          </Button>
        </ButtonContainer>
      </Container>
    </StyledVestingPage>
  );
};

export default VestingPage;
