import styled from "styled-components";
import InfoBox from "./InfoBox";
import { useDispatch, useSelector } from "react-redux";
import { clearWarning, selectWarning } from "../state/uiSlice";
import warningIcon from "../assets/greeble/warning.svg";
import { useEffect, useState } from "react";

const StyledToast = styled.div<{ $show: boolean }>`
  position: fixed;
  bottom: 2rem;
  right: 2rem;

  transform: ${({ $show }) =>
    $show ? "translateY(0)" : "translateY(calc(100% + 2rem))"};

  opacity: ${({ $show }) => ($show ? 1 : 0)};
  transition: all 0.6s ease-in-out;
`;

const Toast = () => {
  const dispatch = useDispatch();
  const warning = useSelector(selectWarning);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!warning) return;
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 3200);
    setTimeout(() => {
      dispatch(clearWarning());
    }, 3800);
  }, [warning, dispatch]);

  return (
    <StyledToast $show={show}>
      <InfoBox warning overlay icon={warningIcon} text={warning || ""} />
    </StyledToast>
  );
};

export default Toast;
