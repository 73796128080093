import { useEffect, useState } from "react";

const useReferralCodeStorage = (): {
  code: string | null | undefined;
  setCode: (code: string) => void;
} => {
  const [code, setCode] = useState<string | null | undefined>(undefined);
  const id = "tlx_referral_code";

  useEffect(() => {
    const code_ = localStorage.getItem(id);
    setCode(code_);
  }, []);

  const setCode_ = (code_: string) => {
    if (!code_) return;
    localStorage.setItem(id, code_);
    setCode(code_);
  };

  return { code, setCode: setCode_ };
};

export default useReferralCodeStorage;
