import { ScaledNumber } from "scaled-number";
import useVelodromePool from "../contracts/use-velodrome-pool";
import { VELODROME_POOL } from "../../constants/addresses";
import { useEffect, useState } from "react";
import useBlock from "../utils/use-block";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import { setWarning } from "../../../state/uiSlice";
import useMulticall from "./use-multicall";

const WETH_USDC_POOL = "0x0493Bf8b6DBB159Ce2Db2E0E8403E753Abd1235b";

const useTlxPrice = (): ScaledNumber | null => {
  const dispatch = useDispatch();
  const tlxPool = useVelodromePool(VELODROME_POOL);
  const usdcPool = useVelodromePool(WETH_USDC_POOL);
  const supportedChain = useSupportedChain();
  const [tlxPrice, setTlxPrice] = useState<ScaledNumber | null>(null);
  const block = useBlock();
  const multicall = useMulticall();

  useEffect(() => {
    const getTlxPrice = async () => {
      if (!tlxPool) return;
      if (!usdcPool) return;
      if (!supportedChain) return;

      try {
        const tlxPoolReserves = await tlxPool.getReserves();
        const usdcPoolReserves = await usdcPool.getReserves();
        const ethTlxPoolReserves = new ScaledNumber(tlxPoolReserves[0]);
        const tlxTlxPoolReserves = new ScaledNumber(tlxPoolReserves[1]);
        const tlxPriceInEth = ethTlxPoolReserves.div(tlxTlxPoolReserves);
        const ethUsdcPoolReserves = new ScaledNumber(usdcPoolReserves[0]);
        const usdcUsdcPoolReserves = new ScaledNumber(usdcPoolReserves[1], 6);
        const ethPriceInUsdc = usdcUsdcPoolReserves.div(ethUsdcPoolReserves);
        const tlxPriceInUsdc = tlxPriceInEth.mul(ethPriceInUsdc);

        setTlxPrice(tlxPriceInUsdc);
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching TLX price"));
      }
    };

    getTlxPrice();
  }, [block, tlxPool, supportedChain, dispatch, usdcPool, multicall]);

  return tlxPrice;
};

export default useTlxPrice;
