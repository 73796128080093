import styled from "styled-components";
import Section from "./Section";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5.5rem;

  @media (max-width: 900px) {
    padding: 0 2rem;
    height: auto;
  }
`;

interface Props {
  percentage: number;
  section: number;
  children: React.ReactNode;
}

const ContentSection = ({ children, percentage, section }: Props) => {
  return (
    <Section percentage={percentage} section={section}>
      <Container>{children}</Container>
    </Section>
  );
};

export default ContentSection;
