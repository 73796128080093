import styled from "styled-components";

import image from "../../assets/greeble/not-found.svg";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { HOME_PATH } from "../../app/constants/paths";

const StyledNotFoundPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;

  @media (max-width: 900px) {
    width: 100%;
    padding: 0 2rem;
  }
`;

const Image = styled.img`
  width: 50rem;
  margin-bottom: 5rem;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Text = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--main);
  max-width: 45rem;
  text-align: center;
  margin-bottom: 5rem;

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <StyledNotFoundPage>
      <Image src={image} alt="Not found image" />
      <Text>
        The page you are looking for doesn't exist. It might have been moved or
        deleted.
      </Text>
      <Button primary action={() => navigate(HOME_PATH)}>
        Back to home page
      </Button>
    </StyledNotFoundPage>
  );
};

export default NotFoundPage;
