import { useEthers } from "@usedapp/core";
import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import useBlock from "../utils/use-block";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import useStaker from "../contracts/use-staker";
import { setWarning } from "../../../state/uiSlice";

const useActiveStakedTlx = (): ScaledNumber | null => {
  const dispatch = useDispatch();
  const supportedChain = useSupportedChain();
  const { account } = useEthers();
  const [amount, setAmount] = useState<ScaledNumber | null>(null);
  const block = useBlock();
  const staker = useStaker();

  useEffect(() => {
    if (!staker) return;
    if (!account) return;
    if (!supportedChain) return;

    const getAmount = async () => {
      try {
        const amount_ = await staker.activeBalanceOf(account);

        setAmount(new ScaledNumber(amount_));
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching active staked TLX"));
      }
    };

    getAmount();
  }, [account, block, supportedChain, staker, dispatch]);

  return amount;
};

export default useActiveStakedTlx;
