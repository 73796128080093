import { useEthers } from "@usedapp/core";
import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import useBlock from "../utils/use-block";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import useStaker from "../contracts/use-staker";
import { setWarning } from "../../../state/uiSlice";

export interface Unstake {
  id: number;
  amount: ScaledNumber;
  unstakes: Date;
}

const useQueuedUnstakes = (): Unstake[] | null => {
  const dispatch = useDispatch();
  const supportedChain = useSupportedChain();
  const { account } = useEthers();
  const [unstakes, setUnstakes] = useState<Unstake[] | null>(null);
  const block = useBlock();
  const staker = useStaker();

  useEffect(() => {
    if (!staker) return;
    if (!account) return;
    if (!supportedChain) return;

    const getAmount = async () => {
      try {
        const unstakes_ = await staker.listQueuedUnstakes(account);

        const unstakes = unstakes_.map((unstake: any) => ({
          id: new ScaledNumber(unstake.id, 0).toNumber(),
          amount: new ScaledNumber(unstake.amount),
          unstakes: new Date(unstake.unstakeTime * 1000),
        }));

        unstakes.sort(
          (a: any, b: any) => a.unstakes.getTime() - b.unstakes.getTime()
        );

        setUnstakes(unstakes);
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching queued unstakes"));
      }
    };

    getAmount();
  }, [account, block, supportedChain, staker, dispatch]);

  return unstakes;
};

export default useQueuedUnstakes;
