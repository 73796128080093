import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import { useDispatch } from "react-redux";
import { setWarning } from "../../../state/uiSlice";

const throwError = (message: string, label: string) => {
  throw new Error(`Error getting ${label} APR: ${message}`);
};

const useDefillamaApr = (
  poolId: string,
  label: string
): ScaledNumber | null => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState<ScaledNumber | null>(null);

  useEffect(() => {
    const getAmount = async () => {
      try {
        const endpoint = `https://yields.llama.fi/chart/${poolId}`;
        const response = await fetch(endpoint);
        const data = await response.json();
        if (!data) throwError("no data", label);
        if (data.status !== "success") throwError("status not success", label);
        if (!data.data) throwError("no data.data", label);
        if (data.data.length === 0) throwError("no data.data length", label);

        const apr = data.data[data.data.length - 1];
        if (!apr) throwError("no apr", label);
        if (!apr.apyReward) throwError("no apr.apyReward", label);
        setAmount(ScaledNumber.fromUnscaled(apr.apyReward / 100));
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning(`Error fetching ${label} APR`));
      }
    };

    getAmount();
  }, [dispatch, poolId, label]);

  return amount;
};

export default useDefillamaApr;
