import styled from "styled-components";
import InfoBox from "../../components/InfoBox";

import timer from "../../assets/greeble/timer.svg";
import checkSquare from "../../assets/greeble/check-square.svg";
import Input from "../../components/Input";
import { useState } from "react";
import Button from "../../components/Button";
import getRoundedScaledNumber from "../../app/helpers/get-rounded-scaled-number";
import useStaker from "../../app/web3/contracts/use-staker";
import { useDispatch } from "react-redux";
import { setError } from "../../state/uiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import useActiveStakedTlx from "../../app/web3/views/use-active-staked-tlx";

const StyledUnstake = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1;
  grid-gap: 2.4rem;
  margin-top: 2.4rem;
`;

interface Props {
  setActiveSwitch: (label: string) => void;
}

const Unstake = ({ setActiveSwitch }: Props) => {
  const dispatch = useDispatch();
  const stTlx = useActiveStakedTlx();
  const [amount, setAmount] = useState("");
  const stakerContract = useStaker();

  const unstakeAmount = getRoundedScaledNumber(amount, stTlx);

  return (
    <StyledUnstake>
      <LoadingSpinner show={!stTlx} />
      {stTlx && stTlx.isZero() && (
        <InfoBox
          icon={checkSquare}
          text="No TLX staked. Stake TLX to attain governance rights & earn sUSD."
        />
      )}
      {stTlx && !stTlx.isZero() && (
        <>
          <InfoBox primary icon={timer} text="Unstaking period is 5 days" />
          <Input
            number
            value={amount}
            setValue={setAmount}
            placeholder="Enter stTLX amount"
            max={stTlx.toNumber()}
          />
          <Button
            web3
            wide
            primary
            disabled={unstakeAmount.isZero()}
            action={async () => {
              if (!stakerContract) return;

              try {
                const tx = await stakerContract.prepareUnstake(
                  unstakeAmount.value
                );
                await tx.wait();
                setActiveSwitch("Withdraw");
              } catch (e: any) {
                dispatch(
                  setError({
                    message: e.message,
                    source: "Unstake/Prepare",
                  })
                );
              }
              setAmount("");
            }}
          >
            {unstakeAmount.isZero()
              ? "Enter an amount"
              : `Unstake ${unstakeAmount.toCryptoString()} stTLX`}
          </Button>
        </>
      )}
    </StyledUnstake>
  );
};

export default Unstake;
