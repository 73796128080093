import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ScaledNumber } from "scaled-number";
import { setWarning } from "../../../state/uiSlice";
import useSynthetixMarket from "../contracts/use-synthetix-market";
import { LeveragedTokenDataType } from "./use-leveraged-token-data";

const useAccessibleMargin = (
  leveragedTokenData: LeveragedTokenDataType | null
): ScaledNumber | null => {
  const dispatch = useDispatch();
  const market = useSynthetixMarket(leveragedTokenData?.targetAsset || null);
  const [amount, setAmount] = useState<ScaledNumber | null>(null);

  const addr = leveragedTokenData?.addr;
  useEffect(() => {
    const getAccessibleMargin = async () => {
      if (!market) return;
      if (!addr) return;

      try {
        const accessibleMargin_ = await market.accessibleMargin(addr);
        setAmount(new ScaledNumber(accessibleMargin_[0]));
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching accessible margin"));
      }
    };

    getAccessibleMargin();
  }, [dispatch, addr, market]);

  return amount;
};

export default useAccessibleMargin;
