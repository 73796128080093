import { useEthers } from "@usedapp/core";
import { useState } from "react";

const useChainId = () => {
  const { library } = useEthers();
  const [chainId, setChainId] = useState<number | null>(null);

  if (library) {
    library.getNetwork().then((network) => {
      setChainId(network.chainId);
    });
  }

  return chainId;
};

export default useChainId;
