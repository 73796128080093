import Banner from "./Banner";

import warning from "../assets/greeble/warning.svg";
import useCountry from "../app/hooks/use-country";
import { BLOCKED_COUNTRIES } from "../app/constants/config";
import { TOS_PATH } from "../app/constants/paths";

const LocationWarning = () => {
  const country = useCountry();
  const isBlocked = country && BLOCKED_COUNTRIES.includes(country);

  if (!isBlocked) return null;

  return (
    <Banner
      dismisable
      id="location-warning-banner"
      icon={warning}
      warning
      label="TLX access is restricted in your region"
      subLabel="View ToS for details"
      subLabelLink={TOS_PATH}
    />
  );
};

export default LocationWarning;
