import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface ErrorType {
  message: string;
  source: string;
}

export interface UiState {
  error: ErrorType | null;
  warning: string | null;
  signature: string | null;
  block: number;
  dismissedBanners: string[];
}

const initialState: UiState = {
  error: null,
  warning: null,
  signature: null,
  block: 0,
  dismissedBanners: [],
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ErrorType>) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    setWarning: (state, action: PayloadAction<string>) => {
      state.warning = action.payload;
    },
    clearWarning: (state) => {
      state.warning = null;
    },
    setSignature: (state, action: PayloadAction<string>) => {
      state.signature = action.payload;
    },
    setBlock: (state, action: PayloadAction<number>) => {
      state.block = action.payload;
    },
    dismissBanner: (state, action: PayloadAction<string>) => {
      state.dismissedBanners.push(action.payload);
    },
  },
});

export const {
  setError,
  clearError,
  setSignature,
  setWarning,
  clearWarning,
  setBlock,
  dismissBanner,
} = uiSlice.actions;

export const selectError = (state: RootState) => state.ui.error;

export const selectWarning = (state: RootState) => state.ui.warning;

export const selectSignature = (state: RootState) => state.ui.signature;

export const selectBlock = (state: RootState) => state.ui.block;

export const selectDismissedBanners = (state: RootState) =>
  state.ui.dismissedBanners;

export default uiSlice.reducer;
