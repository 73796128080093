import styled from "styled-components";

import checkedImage from "../assets/ui/checked.svg";
import uncheckedImage from "../assets/ui/unchecked.svg";

const StyledCheckbox = styled.img`
  height: 3.2rem;

  @media (max-width: 900px) {
    height: 2.4rem;
  }
`;

interface Props {
  checked: boolean;
}

const Checkbox = ({ checked }: Props) => {
  return <StyledCheckbox src={checked ? checkedImage : uncheckedImage} />;
};

export default Checkbox;
