import {
  DAI,
  SUSD,
  TLX_ADDRESS,
  USDC,
  USDCE,
  USDT,
  VELO,
  WETH,
} from "../constants/addresses";

import tlx from "../../assets/tokens/tlx.svg";
import susd from "../../assets/tokens/susd.svg";
import velo from "../../assets/tokens/velo.svg";

const URL =
  "https://raw.githubusercontent.com/SmolDapp/tokenAssets/main/tokens/10/";

const getLogo = (address: string) => {
  return `${URL}${address.toLowerCase()}/logo.svg`;
};

export interface TokenMetadataType {
  name: string;
  symbol: string;
  icon: string;
  address: string;
  decimals: number;
  stable: boolean;
  marketCap?: number; // Used for choosing display order
}

const TOKEN_METADATA: { [key: string]: TokenMetadataType } = {
  tlx: {
    name: "TLX Token",
    symbol: "TLX",
    icon: tlx,
    address: TLX_ADDRESS,
    decimals: 18,
    stable: false,
  },
  susd: {
    name: "Synthetix USD",
    symbol: "sUSD",
    icon: susd,
    address: SUSD,
    decimals: 18,
    stable: true,
    marketCap: 23_848_322,
  },
  velo: {
    name: "Velodrome",
    symbol: "VELO",
    icon: velo,
    address: VELO,
    decimals: 18,
    stable: false,
  },
  dai: {
    name: "DAI",
    symbol: "DAI",
    address: DAI,
    icon: getLogo(DAI),
    decimals: 18,
    stable: true,
    marketCap: 24_318_002,
  },
  usdc: {
    name: "USD Coin",
    symbol: "USDC",
    address: USDC,
    icon: getLogo(USDC),
    decimals: 6,
    stable: true,
    marketCap: 164_683_113,
  },
  usdce: {
    name: "USD Coin (Bridged)",
    symbol: "USDC",
    address: USDCE,
    icon: getLogo(USDCE),
    decimals: 6,
    stable: true,
    marketCap: 195_616_136,
  },
  usdt: {
    name: "Tether USD",
    symbol: "USDT",
    address: USDT,
    icon: getLogo(USDT),
    decimals: 6,
    stable: true,
    marketCap: 794_114_828,
  },
  weth: {
    name: "Wrapped Ether",
    symbol: "WETH",
    address: WETH,
    icon: getLogo(WETH),
    decimals: 18,
    stable: false,
    marketCap: 152_034_091,
  },
};

export default TOKEN_METADATA;

export const getTokenMetadata = (tokenAddress: string) => {
  const metadata = Object.values(TOKEN_METADATA).find(
    (metadata) => metadata.address === tokenAddress
  );
  if (!metadata) throw new Error(`No metadata for ${tokenAddress}`);
  return metadata;
};

const _getTokenId = (tokenAddress: string): string | null => {
  const ids = Object.keys(TOKEN_METADATA);
  const values = Object.values(TOKEN_METADATA);
  const index = values.findIndex(
    (metadata) => metadata.address === tokenAddress
  );
  if (index === -1) return null;
  return ids[index];
};

export const getTokenId = (tokenAddress: string): string => {
  const id = _getTokenId(tokenAddress);
  if (id === null) throw new Error(`No token ID for ${tokenAddress}`);
  return id;
};

export const getTokenDecimals = (tokenAddress: string): number | null => {
  const id = _getTokenId(tokenAddress);
  if (id === null) return null;
  const metadata = TOKEN_METADATA[id];
  if (!metadata) throw new Error(`Error getting decimals ${tokenAddress}`);
  return metadata.decimals;
};
