import styled from "styled-components";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { Mainnet, DAppProvider, Config } from "@usedapp/core";
import "./App.css";

import LandingPage from "./pages/landing/LandingPage";
import Header from "./components/Header";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import StakePage from "./pages/stake/StakePage";
import MintPage from "./pages/mint/MintPage";
import PortfolioPage from "./pages/portfolio/PortfolioPage";
import Footer from "./components/Footer";
import PrivacyPolicyPage from "./pages/privacy-policy/PrivacyPolicyPage";
import ScrollToTop from "./components/ScrollToTop";
import TermsOfServicePage from "./pages/terms-of-service/TermsOfServicePage";
import ErrorHandler from "./components/ErrorHandler";
import WrongNetwork from "./components/WrongNetwork";
import LockPage from "./pages/locker/LockerPage";
import {
  LOCK_PATH,
  MINT_PATH,
  PORTFOLIO_PATH,
  PRIVACY_POLICY_PATH,
  REWARDS_PATH,
  STAKE_PATH,
  TOS_PATH,
  VESTING_PATH,
} from "./app/constants/paths";
import VestingPage from "./pages/vesting/VestingPage";
import RewardsPage from "./pages/rewards/RewardsPage";
import MobileNav from "./components/MobileNav";
import Login from "./components/Login";
import ReferralCodeHandler from "./components/ReferralCodeHandler";
import Toast from "./components/Toast";
import BlockUpdateHandler from "./components/BlockUpdateHandler";
import { HelmetProvider } from "react-helmet-async";

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  align-items: center;
`;

const Layout = () => {
  return (
    <StyledApp>
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
      <MobileNav />
      <ReferralCodeHandler />
      <Toast />
      <ErrorHandler />
      <BlockUpdateHandler />
      <WrongNetwork />
      <Login />
    </StyledApp>
  );
};

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {},
};

const App = () => {
  const location = useLocation();

  return (
    <StyledApp
      style={{
        background: ["/"].includes(location.pathname) ? "none" : "var(--bg)",
      }}
    >
      <DAppProvider config={config}>
        <HelmetProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<LandingPage />} />

              <Route
                path={PRIVACY_POLICY_PATH}
                element={<PrivacyPolicyPage />}
              />
              <Route path={TOS_PATH} element={<TermsOfServicePage />} />
              <Route path={LOCK_PATH} element={<LockPage />} />

              <Route path={VESTING_PATH}>
                <Route index element={<VestingPage />} />
                <Route path=":addressOverride" element={<VestingPage />} />
              </Route>

              <Route path={REWARDS_PATH} element={<RewardsPage />} />
              <Route path={STAKE_PATH} element={<StakePage />} />
              <Route path={MINT_PATH} element={<MintPage />} />
              <Route path={PORTFOLIO_PATH} element={<PortfolioPage />} />

              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </HelmetProvider>
      </DAppProvider>
    </StyledApp>
  );
};

export default App;
