import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --bg: #0E0E0E;
        --bg-1: #161616;
        --bg-2: linear-gradient(180deg, #292929 0%, #202020 0.01%, #1C1C1C 100%);
        --main: #E7E7E7;
        --sub: #B0B0B0;
        --primary: #ED8CB2;
        --primary-sub: #352129;
        --primary-bg: #22181b;
        --success: #2BBF6F;
        --success-bg: #191D1A;
        --warning: #EA9B3E;
        --warning-bg: #1D1B19;
        --disabled: #282828;
        --disabled-text: #6D6D6D;
        --error: #C8385A;

        --border: solid 1px #414141;
        --shadow: rgba(0, 0, 0, 0.4);
        --box-shadow: 4px 4px 7px 0px rgba(18, 18, 18, 0.3);
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-size: 10px;
        font-family: 'aeonik', sans-serif;
    }

    div {
        color: var(--main);
    }

    button {
        background: none;
        border: none;
        outline: none;
    }
    
    input {
        border: none;
        outline: none;
        background: none;
        -moz-appearance: textfield;
        appearance: textfield;

        // Remove arrows from number input
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            display: none;
        }
    }

    a {
        text-decoration: none;
    }
`;

const GlobalStyles = (): JSX.Element => {
  return <GlobalStyle />;
};

export default GlobalStyles;
