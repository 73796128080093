import { useEthers } from "@usedapp/core";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBlock } from "../state/uiSlice";

const BlockUpdateHandler = () => {
  const dispatch = useDispatch();
  const { library } = useEthers();

  const hasLibrary = !!library;
  useEffect(() => {
    if (!hasLibrary) return;

    library.on("block", (blockNumber) => dispatch(setBlock(blockNumber)));

    return () => {
      library.removeAllListeners();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLibrary]);

  return null;
};

export default BlockUpdateHandler;
