import styled from "styled-components";
import { LANDING_PAGE_SECTIONS } from "../app/constants/config";

const StyledSection = styled.div`
  width: 100%;
  height: 100dvh;

  @media (max-width: 900px) {
    opacity: 1 !important;
    height: auto;
  }
`;

interface Props {
  percentage: number;
  section: number;
  children: React.ReactNode;
}

const Section = ({ children, percentage, section }: Props) => {
  const step = 1 / (LANDING_PAGE_SECTIONS - 1);
  const halfStep = step / 2;
  const target = step * section;
  const diff = Math.abs(target - percentage);
  const opacity = Math.max(1 - diff / halfStep, 0);

  return <StyledSection style={{ opacity }}>{children}</StyledSection>;
};

export default Section;
