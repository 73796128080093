import { getAssetData } from "./get-asset-data";

const getLeveragedTokenName = (
  assetId: string,
  leverage: number,
  isLong: boolean
) => {
  const assetData = getAssetData(assetId);

  return `${assetData.symbol} ${leverage}x ${isLong ? "Long" : "Short"}`;
};

export default getLeveragedTokenName;
