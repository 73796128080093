import { useSearchParams } from "react-router-dom";
import useReferralCodeStorage from "../app/hooks/use-referral-code-storage";
import { useEffect } from "react";

const ReferralCodeHandler = () => {
  const [searchParams] = useSearchParams();
  const { setCode } = useReferralCodeStorage();

  useEffect(() => {
    const ref = searchParams.get("ref");
    if (!ref) return;
    setCode(ref);
  }, [searchParams, setCode]);

  return null;
};

export default ReferralCodeHandler;
