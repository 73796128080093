import styled from "styled-components";
import { shortenAddress, useEthers } from "@usedapp/core";

import Button from "./Button";

const StyledConnector = styled.div`
  display: flex;
`;

const Connector = () => {
  const { account, activateBrowserWallet, active } = useEthers();

  const connected = active && account;

  return (
    <>
      <StyledConnector>
        <Button
          primary={!connected}
          action={() => {
            if (!connected) activateBrowserWallet();
          }}
        >
          {connected ? shortenAddress(account) : "Connect"}
        </Button>
      </StyledConnector>
    </>
  );
};

export default Connector;
