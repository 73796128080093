import styled from "styled-components";
import Popup from "./Popup";
import { useLocation, useNavigate } from "react-router-dom";
import useChainId from "../app/web3/utils/use-chain-id";
import Button from "./Button";
import { useEthers } from "@usedapp/core";

const StyledWrongNetwork = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  width: 33rem;
`;

const Paragraph = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 2.4rem;
`;

const WrongNetwork = () => {
  const chainId = useChainId();
  const navigate = useNavigate();
  const location = useLocation();
  const { switchNetwork } = useEthers();

  return (
    <Popup
      open={chainId !== null && chainId !== 10 && location.pathname !== "/"}
      close={() => navigate("/")}
      header="Unsupported Network"
    >
      <StyledWrongNetwork>
        <Paragraph>
          TLX currently only supports Optimism. Switch to Optimism to continue
          using TLX.
        </Paragraph>
        <Button wide action={() => switchNetwork(10)} primary>
          Switch to Optimism
        </Button>
      </StyledWrongNetwork>
    </Popup>
  );
};

export default WrongNetwork;
