import Banner from "./Banner";
import { OP_INCENTIVES_BLOG } from "../app/constants/urls";

const END_DATE = "Dec. 16th";

const OpIncentivesBanner = () => {
  return (
    <Banner
      id="op-incentives-banner"
      dismisable
      label={`Every trade earns $OP rewards. From now until ${END_DATE}`}
      buttonText="Learn more"
      buttonAction={() => window.open(OP_INCENTIVES_BLOG, "_blank")?.focus()}
    />
  );
};

export default OpIncentivesBanner;
