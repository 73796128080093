import styled from "styled-components";

import Tooltip from "./Tooltip";

const InputHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
`;

const StyledInputHeader = styled.p`
  font-size: 1.6rem;
  font-weight: 400;

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

interface Props {
  header: string;
  tooltip: string;
}

const InputHeader = ({ header, tooltip }: Props) => {
  return (
    <InputHeaderContainer>
      <StyledInputHeader>{header}</StyledInputHeader>
      <Tooltip>{tooltip}</Tooltip>
    </InputHeaderContainer>
  );
};

export default InputHeader;
