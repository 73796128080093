import styled from "styled-components";
import { CardFrame } from "../styles/Frames";
import Button from "./Button";
import { DISCORD } from "../app/constants/urls";
import { MINTING_DISABLED } from "../app/constants/config";

const StyledMintingDisabled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

const Container = styled(CardFrame)`
  position: relative;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    padding: 1.6rem;
    width: 100%;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2.4rem;

  @media (max-width: 900px) {
    margin-bottom: 1.6rem;
  }
`;

const Header = styled.h2`
  font-size: 3.2rem;
  font-weight: 300;
  flex: 1;
  width: 100%;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 2.4rem;
  }
`;

const SubHeader = styled.div`
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--sub);
  max-width: 50rem;
  margin-bottom: 2.4rem;

  @media (max-width: 900px) {
    font-size: 1.4rem;
    margin-bottom: 1.6rem;
  }
`;

const MintingDisabled = () => {
  if (!MINTING_DISABLED) return null;

  return (
    <StyledMintingDisabled>
      <Background />
      <Container>
        <HeaderContainer>
          <Header>Minting Currently Paused</Header>
        </HeaderContainer>
        <SubHeader>
          Minting of new leveraged tokens is currently disabled. Given the
          unusually high trading volume recently as well some suspicious trading
          activity, minting has been paused as a precautionary measure. All
          funds are safe and this does not affect current leveraged token
          holders, redemptions remain enabled. Join the discord for updates.
        </SubHeader>
        <Button
          wide
          primary
          action={() => {
            window.open(DISCORD, "_blank")?.focus();
          }}
        >
          TLX Discord
        </Button>
      </Container>
    </StyledMintingDisabled>
  );
};

export default MintingDisabled;
