import styled from "styled-components";
import {
  Header1,
  Header3,
  List,
  ListItem,
  Paragraph,
} from "../../styles/content";

const StyledPrivacyPolicyPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  padding: 4rem;
  max-width: 770px;
`;

const PrivacyPolicyPage = () => {
  return (
    <StyledPrivacyPolicyPage>
      <Header1>Privacy Policy</Header1>

      <Paragraph>Last modified: December 6th, 2023 </Paragraph>

      <Header3>Introduction</Header3>

      <Paragraph>
        Reactive Labs, a Cayman Islands registered company (the “Company,” “we,”
        “our,” or “us”) takes protecting your privacy very seriously. This
        Privacy Policy explains the types of information we may collect from you
        or that you may provide when you visit the TLX website located at
        https://tlx.fi/ and its related sub-pages (collectively, the “Website”)
        or use our software applications, and our practices for collecting,
        using, maintaining, and disclosing that information.
      </Paragraph>
      <Paragraph>
        This Privacy Policy applies to information we collect:
      </Paragraph>

      <List>
        <ListItem>On the Website.</ListItem>
        <ListItem>
          In email, text, and other electronic messages between you and the
          Company.
        </ListItem>
        <ListItem>
          Information provided to us through software applications we host. As
          used herein, “our software applications” refers to all of the above.
        </ListItem>
      </List>

      <Paragraph>
        Please read this Privacy Policy carefully to understand how we interact
        with your personal information and what we do with it. By using the
        Website and providing us with your information, you agree to this
        Privacy Policy. This Privacy Policy may change from time to time, and by
        continuing to use the Website, you agree to those changes. We encourage
        you to check this Privacy Policy periodically for updates.
      </Paragraph>

      <Header3>Children Under the Age of 18.</Header3>

      <Paragraph>
        Our Website and software applications are not intended for children
        under eighteen (18) years of age. No one under age eighteen (18) may
        provide any information to us, including on the Website and our software
        applications. We do not knowingly collect personal information from
        children under eighteen (18). If you are under eighteen (18), do not use
        or provide any information on this Website and our software applications
        or through any of its features, or provide any information about
        yourself to us, including your name, address, telephone number, email
        address, any identity or records related to you, or any screen name or
        user name you may use. If we learn we have collected or received
        personal information from a child under eighteen (18) without
        verification of parental consent, we will delete that information. If
        you believe we might have any information from or about a child under
        eighteen (18), please contact us at legal@tlx.fi.
      </Paragraph>

      <Header3>Collection of Data.</Header3>

      <Paragraph>
        Information You Provide to Us: To open an account and access our
        services, we may ask you to provide us with some information about
        yourself, including but not limited to your wallet address. We collect
        information and other data you add to your account, such as linked
        social media accounts. This information is necessary to provide the
        requested services or may be relevant for certain specified purposes,
        described in greater detail in section “How We Use Your Information &
        Why We Collect It.” of this Privacy Policy. In some cases, if we add
        services and features you may be asked to provide us with additional
        information.
      </Paragraph>
      <Paragraph>
        Information You May Provide to Us in the Future: The information we may
        collect on or through our Website or our software applications may
        include:
      </Paragraph>

      <List>
        <ListItem>Your IP address;</ListItem>
        <ListItem>
          Information by which you may be personally identified, such as name,
          email address, or any other identifier through which you may be
          contacted online or offline (“personal information”);
        </ListItem>
        <ListItem>
          Details of transactions you carry out through our Website or through
          our software applications;
        </ListItem>
        <ListItem>
          Information about blockchain transactions, including but not limited
          to your public key address or other public identifier related to your
          blockchain account and information about how you are using the
          Company’s blockchain infrastructure;
        </ListItem>
        <ListItem>
          Records and copies of your correspondence, including email addresses,
          if you contact us;
        </ListItem>
        <ListItem>
          Transcripts of your interaction with our Website’s chat feature; or
        </ListItem>
        <ListItem>
          Details regarding how you found our site and how you intend to use it,
          if applicable.
        </ListItem>
      </List>

      <Paragraph>
        You also may provide information to be published or transmitted to other
        users of the Website or our software applications, or third parties
        (collectively, “User Contributions”). Your User Contributions are
        provided at your own risk. Additionally, we cannot control the actions
        of other users of the Website or our software applications with whom you
        may choose to share your User Contributions. Therefore, we cannot and do
        not guarantee that your User Contributions will not be viewed by
        unauthorized persons.
      </Paragraph>
      <Paragraph>
        If you submit information to us that we believe places your privacy or
        security at risk, we may immediately delete that information.
      </Paragraph>

      <Paragraph>
        Information Collected Automatically: As you navigate through and
        interact with our Website, we may use automatic data collection
        technologies to collect certain information about your equipment,
        browsing actions, and patterns, including:
      </Paragraph>

      <List>
        <ListItem>
          Usage details, including the total time you spend on our Website, the
          time you spend on each page, the order in which those pages were
          visited and the internal links clicked, the general geographic
          location from which you access our Website, which browser and
          operating system you are using to visit our Website, and the referring
          website;
        </ListItem>
        <ListItem>
          Performance details, including monitor page load times, CPU/memory
          usage, browser crashes and React component rendering; or
        </ListItem>
        <ListItem>
          Information about your computer and internet connection, including
          your IP address, operating system, and browser type.
        </ListItem>
      </List>
      <Paragraph>
        We also may use these technologies to collect information about your
        online activities over time and across third-party websites or other
        online services (behavioral tracking). The information we collect
        automatically is only statistical data and does not include personal
        information, but we may maintain it or associate it with personal
        information we collect in other ways or receive from third parties. It
        helps us to improve our Website and to deliver a better and more
        personalized service, including by enabling us to:
      </Paragraph>
      <List>
        <ListItem>Estimate our audience size and usage patterns;</ListItem>
        <ListItem>
          Store information about your preferences, allowing us to customize our
          Website according to your usage patterns; and
        </ListItem>
        <ListItem>Recognize you when you return to our Website.</ListItem>
      </List>

      <Paragraph>
        The information we collect automatically is only statistical data and
        does not, itself, include personal information, though it may be
        correlated with other personal information to build a unique profile
        about you.
      </Paragraph>

      <Paragraph>
        The technologies we use for this automatic data collection may include:
      </Paragraph>
      <List>
        <ListItem>
          Cookies (or browser cookies). A cookie is a small file placed on the
          hard drive of your computer. You may refuse to accept browser cookies
          by activating the appropriate setting on your browser. However, if you
          select this setting, you may be unable to access certain parts of our
          Website. Unless you have adjusted your browser setting so that it will
          refuse cookies, our system will issue cookies when you direct your
          browser to our Website.
        </ListItem>
        <ListItem>
          Web Beacons. Pages of the Website and our emails may contain small
          electronic files known as web beacons (also referred to as clear gifs,
          pixel tags, and single-pixel gifs) that permit the Company, for
          example, to count users who have visited those pages or opened an
          email and for other related website statistics (for example, verifying
          system and server integrity).
        </ListItem>
      </List>

      <Paragraph>
        We also collect data concerning the users of our applications. For
        example, any information that you may provide when creating an account
        (including personal identifying information) will be retained by us. We
        also collect IP addresses, and information on the activities of the
        users of our applications, both on an individual and aggregate level,
        including, for example, how often a user uses a certain platform and
        what activities the user engages in on a platform.
      </Paragraph>

      <Header3>How We Use Your Information & Why We Collect It.</Header3>

      <Paragraph>
        We use information that we collect about you or that you provide to us,
        including any personal information:
      </Paragraph>
      <List>
        <ListItem>
          To present our Website, our software applications, and their contents
          to you;
        </ListItem>
        <ListItem>To manage our relationship with you;</ListItem>
        <ListItem>
          To communicate with users on how to use our services;
        </ListItem>
        <ListItem>To market our services and features;</ListItem>
        <ListItem>
          To fulfill any other purpose for which you provide it (including to
          interact with our affiliates or subsidiaries, or third parties that
          are associated with a transaction that is carried out through our
          Website or software applications);
        </ListItem>
        <ListItem>
          To carry out our obligations and enforce our rights under our Terms of
          Use, including restricting access to the Website and our software
          applications from specific Prohibited Jurisdictions (as defined in our
          Terms of Use);
        </ListItem>
        <ListItem>
          To allow you to participate in interactive features on our Website and
          our software applications;
        </ListItem>
        <ListItem>
          To help facilitate the ongoing development of our services;
        </ListItem>
        <ListItem>
          In any other way we may describe when you provide the information; or
        </ListItem>
        <ListItem>For any other purpose with your consent.</ListItem>
      </List>

      <Header3>
        Third-Party Use of Cookies & Other Tracking Technologies.
      </Header3>

      <Paragraph>
        Some content or applications, including advertisements, on the Website
        or in our applications may be served by third-parties, content
        providers, and application providers. These third parties may use
        cookies alone or in conjunction with web beacons or other tracking
        technologies to collect information about you when you use our website.
        The information they collect may be associated with your personal
        information or they may collect information, including personal
        information, about your online activities over time and across different
        websites and other online services. They may use this information to
        provide you with interest-based (behavioral) advertising or other
        targeted content. We do not control these third parties’ tracking
        technologies or how they may be used. If you have any questions about an
        advertisement or other targeted content, you should contact the
        responsible provider directly.
      </Paragraph>

      <Header3>Disclosure of Your Information.</Header3>

      <Paragraph>We may also disclose your personal information:</Paragraph>

      <List>
        <ListItem>
          To comply with any court order, law, or legal process, including to
          respond to any government or regulatory request;
        </ListItem>
        <ListItem>
          To enforce or apply our Terms of Use and other agreements, including
          for billing and collection purposes; or
        </ListItem>
        <ListItem>
          If we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of the Company, our customers, or others.
          This includes exchanging information with other companies and
          organizations for the purposes of fraud protection, credit risk
          reduction, or identifying malicious actors using our products, or the
          Company’s affiliates or subsidiaries.
        </ListItem>
      </List>

      <Paragraph>
        We do not sell your personal information to third parties for monetary
        compensation.
      </Paragraph>

      <Header3>Privacy & The Blockchain.</Header3>

      <Paragraph>
        A key feature of many blockchain technologies, including any blockchain
        on which the Company’s services rely, is the transparency and public
        accessibility of on-chain transactions. As such, any information stored
        on-chain may be public, immutable, and not easily removed or deleted
        and, in many cases, cannot be deleted. Your account with the Company may
        reveal information about you and this information can potentially be
        correlated now or in the future by any party who chooses to do so,
        including law enforcement. If you are unfamiliar with blockchain
        technology and its transparent and public nature, we strongly encourage
        you to conduct your own research into blockchain before electing to use
        our services.
      </Paragraph>

      <Header3>Data Security.</Header3>

      <Paragraph>
        We have implemented measures designed to secure your personal
        information from accidental loss and from unauthorized access, use,
        alteration, and disclosure. The safety and security of your information
        also depend on you.
      </Paragraph>

      <Paragraph>
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we do our best to protect your personal
        information, we cannot guarantee the security of your personal
        information transmitted to our Website. Any transmission of personal
        information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures contained on
        the Website or our software applications.
      </Paragraph>

      <Header3>Special Disclosures for European Users.</Header3>

      <Paragraph>
        Under the European General Data Protection Act, European Union users are
        entitled to the following:
      </Paragraph>

      <List>
        <ListItem>
          The right to access – You have the right to request for copies of your
          personal data from us. We may charge you a small fee for this service.
        </ListItem>
        <ListItem>
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate. You also have the
          right to request that we complete the information you believe is
          incomplete.
        </ListItem>
        <ListItem>
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions.
        </ListItem>
        <ListItem>
          The right to restrict processing – You have the right to request that
          we restrict the processing of your personal data, under certain
          conditions.
        </ListItem>
        <ListItem>
          The right to object to processing – You have the right to object to
          our processing of your personal data, under certain conditions.
        </ListItem>
        <ListItem>
          The right to data portability – You have the right to request we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </ListItem>
      </List>

      <Paragraph>
        If you make a request, we will in most cases respond to you in one
        month. If you would like to exercise any of these rights, please contact
        us at legal@tlx.fi.
      </Paragraph>

      <Header3>
        Data Protection Rights Under the California Consumer Privacy Act (CCPA)
      </Header3>

      <Paragraph>
        If you are a California resident, you are entitled to learn what data we
        collect about you, ask us to delete your data, and not to share it. To
        exercise your data protection rights, you can ask us:
      </Paragraph>

      <List>
        <ListItem>
          What personal information we have about you. If you make this request,
          we will return to you:
        </ListItem>
        <ListItem>
          The categories of personal information we have collected about you.
        </ListItem>
        <ListItem>
          The categories of sources from which we collect your personal
          information.
        </ListItem>
        <ListItem>
          The business or commercial purpose for collecting your personal
          information.
        </ListItem>
        <ListItem>
          The categories of third parties with whom we share your personal
          information.
        </ListItem>
        <ListItem>
          The specific pieces of personal information we have collected about
          you.
        </ListItem>
        <ListItem>
          A list of categories of personal information that we have sold, along
          with the category of any other company we sold it to. If we have not
          sold your information, we will inform you of that fact.
        </ListItem>
        <ListItem>
          A list of categories of personal information that we have disclosed
          for business purposes, along with the category of any other company we
          shared it with.
        </ListItem>
      </List>

      <Paragraph>
        Please note, you are entitled to ask us to provide this information
        twice at any point in a rolling twelve (12) month period. When you make
        this request, the information we provide will be limited to the previous
        twelve (12) months.
      </Paragraph>
      <List>
        <ListItem>
          To delete your personal information. If you make this request, we will
          delete the personal information we hold about you as of the date of
          your request from our records and direct any service providers to do
          the same. In some cases, deletion may be accomplished through
          de-identification of the information. If you choose to delete your
          personal information, you may not be able to use certain functions
          that require your personal information to operate.
        </ListItem>
        <ListItem>
          To stop selling your personal information. If you submit a request to
          stop selling your personal information, we will stop selling it. If
          you are a California resident, to opt-out of the sale of your personal
          information, please email such request to legal@tlx.fi.
        </ListItem>
      </List>
      <Paragraph>
        Please note, if you ask us to delete or stop selling your data, it may
        impact your experience with us, and you may not be able to participate
        in certain programs or membership services which require the usage of
        your personal information to function. In no circumstances we will
        discriminate against you for exercising your rights.
      </Paragraph>
      <Paragraph>
        To exercise your California data protection rights described above,
        please send your request(s) to legal@tlx.fi.
      </Paragraph>

      <Header3>Changes to Our Privacy Policy.</Header3>
      <Paragraph>
        The date the Privacy Policy was last revised is identified at the top of
        the page. You are responsible for periodically visiting our Website and
        this Privacy Policy to check for any changes. If someone does not agree
        with this Privacy Policy, they should refrain from using our website and
        platforms. Continued use of the Website or our software applications
        after having been informed of any such changes to these conditions
        implies acceptance of the revised privacy policy.
      </Paragraph>

      <Header3>What If I Have Questions About this Privacy Policy?</Header3>

      <Paragraph>
        If you have any questions ggabout this Privacy Policy, please send a
        detailed message to legal@tlx.fi and we will try to resolve your
        concerns and provide further information.
      </Paragraph>
    </StyledPrivacyPolicyPage>
  );
};

export default PrivacyPolicyPage;
