import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setWarning } from "../../state/uiSlice";
import { ChartType } from "./use-chart";

const MS_IN_DAY = 1000 * 60 * 60 * 24;
const PERIOD_DAYS = 7;
const now = new Date();
const fromDate = new Date(now.getTime() - MS_IN_DAY * PERIOD_DAYS);

const BASE_URL = "https://api.tlx.fi/functions/v1/prices/";
const QUERY = `?granularity=6hours&from=${fromDate.toISOString()}`;

const useLeveragedTokenChart = (
  address: string | undefined
): ChartType | null => {
  const dispatch = useDispatch();
  const [chart, setChart] = useState<ChartType | null>(null);

  useEffect(() => {
    if (!address) return;

    const fetchChart = async (address: string) => {
      try {
        const url = `${BASE_URL}${address}${QUERY}`;
        const response = await fetch(url);
        const data: { timestamp: string; price: number }[] =
          await response.json();

        const prices = data.map((price) => ({
          time: new Date(price.timestamp),
          price: price.price,
        }));

        const chart_: ChartType = {
          prices: prices,
        };

        setChart(chart_);
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching chart data"));
      }
    };

    fetchChart(address);
  }, [address, dispatch]);

  return chart;
};

export default useLeveragedTokenChart;
