import { useEthers } from "@usedapp/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSignature, setSignature } from "../../state/uiSlice";

const useLoginSignature = () => {
  const dispatch = useDispatch();
  const { account } = useEthers();
  const signature = useSelector(selectSignature);

  const id = `tlx_login_signature_${account}`;

  useEffect(() => {
    if (!account) return;
    const signature_ = localStorage.getItem(id);
    if (!signature_) return;
    dispatch(setSignature(signature_));
  }, [account, dispatch, id]);

  const setSignature_ = (signature_: string) => {
    localStorage.setItem(id, signature_);
    dispatch(setSignature(signature_));
  };

  return { signature, setSignature: setSignature_ };
};

export default useLoginSignature;
