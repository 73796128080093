import styled from "styled-components";

import Switch from "../../components/Switch";
import { useState } from "react";

import { CardFrame } from "../../styles/Frames";
import Stake from "./Stake";
import Unstake from "./Unstake";

import susd from "../../assets/ui/susd.svg";
import external from "../../assets/ui/external-pink.svg";
import { STAKE_DOCS, TLX_YIELD_SERVER } from "../../app/constants/urls";
import useTokenBalance from "../../app/web3/views/use-token-balance";
import { TLX_ADDRESS } from "../../app/constants/addresses";
import useTlxPrice from "../../app/web3/views/use-tlx-price";
import { ScaledNumber } from "scaled-number";
import useStakedTlx from "../../app/web3/views/use-staked-tlx";
import useStakerApr from "../../app/web3/views/use-staker-apr";
import Seo from "../../components/Seo";
import OpIncentivesBanner from "../../components/OpIncentivesBanner";
import Withdraw from "./Withdraw";
import AprTooltip from "../../components/AprTooltip";

const StyledStakePage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 4rem;

  @media (max-width: 900px) {
    padding: 2rem;
  }
`;

const Container = styled(CardFrame)`
  display: flex;
  width: 100%;
  padding: 4rem;
  max-width: 76rem;

  > div:first-child {
    margin-right: 4rem;
  }

  @media (max-width: 900px) {
    padding: 2rem;
    flex-direction: column;

    > div:first-child {
      margin-right: none;
    }
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
`;

const Header = styled.div`
  font-size: 2.4rem;
  font-weight: 300;
  color: var(--sub);
`;

const Apr = styled.div`
  font-size: 3.2rem;
  font-weight: 400;
  font-size: 4rem;
  font-weight: 300;
  color: var(--success);
`;

const SubHeader = styled.div`
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--sub);
  margin-bottom: 4rem;
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 4rem;
`;

const ColumnRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StatHeader = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 0.4rem;
`;

const StatValue = styled.div`
  font-size: 2.4rem;
  font-weight: 300;
  margin-bottom: 0.4rem;
`;

const StatSusdContaienr = styled.div`
  display: flex;
  align-items: center;
`;

const StatSusdValue = styled.div`
  font-size: 1.4rem;
  color: var(--sub);
  margin-right: 0.6rem;
`;

const StatSusdIcon = styled.img`
  height: 1.5rem;
`;

const DocsContainer = styled.div`
  display: flex;

  @media (max-width: 900px) {
    display: none;
  }
`;

const DocsText = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
`;

const DocsLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--primary);
  margin-left: 0.4rem;
  cursor: pointer;
`;

const ExternalIcon = styled.img`
  height: 1.2rem;
  margin-left: 0.2rem;
`;

const StakePage = () => {
  const tlxBalance = useTokenBalance(TLX_ADDRESS);
  const tlxPrice = useTlxPrice();
  const stakedTlx = useStakedTlx();
  const apr = useStakerApr();

  const [activeSwitch, setActiveSwitch] = useState("Stake");

  return (
    <StyledStakePage>
      <Seo
        title="Stake TLX"
        description="Attain governance rights & earn 100% of protocol fees by staking your TLX."
      />
      <OpIncentivesBanner />
      <Container>
        <Row>
          <HeaderContainer>
            <Header>APR</Header>
            <AprTooltip codeLink={TLX_YIELD_SERVER} />
          </HeaderContainer>
          <Apr>
            {apr
              ? apr.lt(ScaledNumber.fromUnscaled("0.0001"))
                ? "0"
                : apr.toPercent()
              : "---"}
          </Apr>
          <SubHeader>In sUSD rewards</SubHeader>
          <Column>
            <ColumnRow>
              <StatHeader>Your TLX</StatHeader>
              <StatValue>
                {tlxBalance
                  ? tlxBalance.lt(ScaledNumber.fromUnscaled("0.00000001"))
                    ? "0"
                    : tlxBalance.toCryptoString()
                  : "---"}
              </StatValue>
              <StatSusdContaienr>
                <StatSusdValue>
                  {tlxBalance && tlxPrice
                    ? tlxBalance.toUsdValue(tlxPrice.toNumber())
                    : "$---"}
                </StatSusdValue>
                <StatSusdIcon src={susd} alt="sUSD" />
              </StatSusdContaienr>
            </ColumnRow>
            <ColumnRow>
              <StatHeader>Your Staked TLX</StatHeader>
              <StatValue>
                {stakedTlx
                  ? stakedTlx.lt(ScaledNumber.fromUnscaled("0.00000001"))
                    ? "0"
                    : stakedTlx.toCryptoString()
                  : "---"}
              </StatValue>
              <StatSusdContaienr>
                <StatSusdValue>
                  {stakedTlx && tlxPrice
                    ? stakedTlx.toUsdValue(tlxPrice.toNumber())
                    : "$---"}
                </StatSusdValue>
                <StatSusdIcon src={susd} alt="sUSD" />
              </StatSusdContaienr>
            </ColumnRow>
          </Column>
          <DocsContainer>
            <DocsText>Learn about staking in the </DocsText>
            <DocsLink href={STAKE_DOCS} target="_blank">
              Docs <ExternalIcon src={external} alt="external link icon" />
            </DocsLink>
          </DocsContainer>
        </Row>
        <Row>
          <Switch
            switches={["Stake", "Unstake", "Withdraw"]}
            active={activeSwitch}
            setActive={setActiveSwitch}
          />
          {activeSwitch === "Stake" && <Stake />}
          {activeSwitch === "Unstake" && (
            <Unstake setActiveSwitch={setActiveSwitch} />
          )}
          {activeSwitch === "Withdraw" && <Withdraw />}
        </Row>
      </Container>
    </StyledStakePage>
  );
};

export default StakePage;
