import styled from "styled-components";
import InfoBox from "../../components/InfoBox";
import checkSquare from "../../assets/greeble/check-square.svg";
import getCountdownText from "../../app/helpers/get-countdown-text";
import useQueuedUnstakes from "../../app/web3/views/use-queued-unstakes";
import useStaker from "../../app/web3/contracts/use-staker";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useState } from "react";
import tlxLogo from "../../assets/tokens/tlx.svg";
import { GlossFrameDark } from "../../styles/Frames";
import Button from "../../components/Button";
import { setError } from "../../state/uiSlice";

const StyledWithdraw = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1;
  grid-gap: 2.4rem;
  margin-top: 2.4rem;
`;

const UnstakeButton = styled.button`
  cursor: pointer;
`;

const UnstakeContainer = styled(GlossFrameDark)<{ $active: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  height: 6rem;
  border-radius: 1.6rem;
  justify-content: space-between;
  padding: 0 2rem;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
`;

const TlxLogo = styled.img`
  height: 2.8rem;
  margin-right: 1.2rem;
`;

const TlxAmount = styled.div`
  font-size: 1.8rem;
  font-weight: 400;
`;

const ReadyText = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--success);
`;

const PendingText = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--sub);
`;

const Withdraw = () => {
  const dispatch = useDispatch();
  const unstakes = useQueuedUnstakes();
  const stakerContract = useStaker();
  const [active, setActive] = useState<number | null>(0);

  return (
    <StyledWithdraw>
      <LoadingSpinner show={!unstakes} />
      {unstakes && unstakes.length === 0 && (
        <InfoBox
          icon={checkSquare}
          text="No pending withdrawals. You must first unstake your TLX to withdraw it."
        />
      )}
      {unstakes &&
        unstakes.map((unstake, index) => (
          <UnstakeButton key={unstake.id} onClick={() => setActive(index)}>
            <UnstakeContainer $active={active === index}>
              <Section>
                <TlxLogo src={tlxLogo} />
                <TlxAmount>{unstake.amount.toCryptoString()}</TlxAmount>
              </Section>
              {unstake.unstakes.getTime() >= new Date().getTime() && (
                <PendingText>{getCountdownText(unstake.unstakes)}</PendingText>
              )}
              {unstake.unstakes.getTime() < new Date().getTime() && (
                <ReadyText>ready to unstake</ReadyText>
              )}
            </UnstakeContainer>
          </UnstakeButton>
        ))}
      {unstakes && unstakes.length > 0 && active === null && (
        <Button wide disabled primary action={() => {}}>
          Select an unstake
        </Button>
      )}
      {unstakes &&
        unstakes.length > 0 &&
        active !== null &&
        unstakes[active].unstakes.getTime() < new Date().getTime() && (
          <Button
            web3
            wide
            primary
            action={async () => {
              if (!stakerContract) return;

              try {
                const tx = await stakerContract.unstake(unstakes[active].id);
                await tx.wait();
              } catch (e: any) {
                dispatch(
                  setError({
                    message: e.message,
                    source: "Withdraw/Execute",
                  })
                );
              }
            }}
          >
            {`Withdraw ${unstakes[active].amount.toCryptoString()} stTLX`}
          </Button>
        )}
      {unstakes &&
        unstakes.length > 0 &&
        active !== null &&
        unstakes[active].unstakes.getTime() >= new Date().getTime() && (
          <Button
            web3
            wide
            primary
            action={async () => {
              if (!stakerContract) return;

              try {
                const tx = await stakerContract.restake(unstakes[active].id);
                await tx.wait();
              } catch (e: any) {
                dispatch(
                  setError({
                    message: e.message,
                    source: "Withdaw/Restake",
                  })
                );
              }
            }}
          >
            {`Restake ${unstakes[active].amount.toCryptoString()} stTLX`}
          </Button>
        )}
    </StyledWithdraw>
  );
};

export default Withdraw;
