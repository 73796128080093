import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import useBlock from "../utils/use-block";
import useReferrals from "../contracts/use-referrals";
import { useEthers } from "@usedapp/core";
import { parseBytes32String } from "ethers/lib/utils";
import { setWarning } from "../../../state/uiSlice";

const useReferralCode = () => {
  const dispatch = useDispatch();
  const supportedChain = useSupportedChain();
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const referralsContract = useReferrals();
  const block = useBlock();
  const { account } = useEthers();

  useEffect(() => {
    if (!referralsContract) return;
    if (!supportedChain) return;
    if (!account) return;

    const getAmount = async () => {
      try {
        const referralCodeBytes = await referralsContract.referral(account);
        const referralCode_ = parseBytes32String(referralCodeBytes);
        setReferralCode(referralCode_);
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching referral code"));
      }
    };

    getAmount();
  }, [supportedChain, referralsContract, dispatch, block, account]);

  return referralCode;
};

export default useReferralCode;
