import styled from "styled-components";
import InfoBox from "../../components/InfoBox";

import checkSquare from "../../assets/greeble/check-square.svg";
import { useState } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { TLX_ADDRESS } from "../../app/constants/addresses";
import useTokenBalance from "../../app/web3/views/use-token-balance";
import useApprovedAmount from "../../app/web3/views/use-approved-amount";
import useToken from "../../app/web3/contracts/use-token";
import { useDispatch } from "react-redux";
import { setError } from "../../state/uiSlice";
import getRoundedScaledNumber from "../../app/helpers/get-rounded-scaled-number";
import InputHeader from "../../components/InputHeader";
import { Splitter } from "../../styles/utils";
import useStaker from "../../app/web3/contracts/use-staker";

const StyledStake = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1;
  grid-gap: 2.4rem;
  margin-top: 2.4rem;
`;

const Stake = () => {
  const dispatch = useDispatch();
  const tlxBalance = useTokenBalance(TLX_ADDRESS);
  const stakerContract = useStaker();
  const tlxContract = useToken(TLX_ADDRESS);
  const approvedAmount = useApprovedAmount(
    TLX_ADDRESS,
    stakerContract?.address
  );

  const [amount, setAmount] = useState("");

  const stakeAmount = getRoundedScaledNumber(amount, tlxBalance);
  const approved = approvedAmount && approvedAmount.gte(stakeAmount);

  return (
    <StyledStake>
      <InfoBox
        icon={checkSquare}
        text="Attain governance rights & earn 100% of protocol fees by staking your TLX"
      />
      <Splitter>
        <InputHeader
          header="TLX Amount"
          tooltip="The amount of TLX you would like to stake"
        />
        <Input
          number
          value={amount}
          setValue={(value: string) => setAmount(value)}
          placeholder="Enter TLX amount"
          max={tlxBalance ? tlxBalance.toNumber() : 0}
        />
      </Splitter>
      <Button
        web3
        wide
        primary
        disabled={stakeAmount.isZero()}
        action={async () => {
          if (!stakerContract) return;
          if (!tlxContract) return;

          if (!approved) {
            try {
              const tx = await tlxContract.approve(
                stakerContract.address,
                stakeAmount.value
              );
              await tx.wait();
            } catch (e: any) {
              dispatch(
                setError({
                  message: e.message,
                  source: "Stake/Approve",
                })
              );
            }
            return;
          }

          try {
            const tx = await stakerContract.stake(stakeAmount.value);
            await tx.wait();
          } catch (e: any) {
            dispatch(
              setError({
                message: e.message,
                source: "Stake/Stake",
              })
            );
          }
          setAmount("");
        }}
      >
        {stakeAmount.isZero()
          ? "Enter an amount"
          : !approved
          ? "Approve Staker"
          : `Stake ${stakeAmount.toCryptoString()} TLX`}
      </Button>
    </StyledStake>
  );
};

export default Stake;
