import { useSelector } from "react-redux";
import { selectBlock } from "../../../state/uiSlice";
import { useDebounce } from "@usedapp/core/dist/esm/src/hooks";
import { DEBOUNCE_DELAY } from "../../constants/config";

const useBlock = () => {
  const block_ = useSelector(selectBlock);
  const block = useDebounce(block_, DEBOUNCE_DELAY);
  return block;
};

export default useBlock;
