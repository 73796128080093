export const SUPPORTED_ASSETS: string[] = [
  "ETH",
  "SOL",
  "LINK",
  "BTC",
  "OP",
  "PEPE",
  "DOGE",
  "ETHBTC",
  "SUI",
  "SEI",
  "RUNE",
];
