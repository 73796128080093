import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setWarning } from "../../../state/uiSlice";
import useSynthetixHandlerStorage from "../contracts/use-synthetix-handler-storage";
import { useEthers } from "@usedapp/core";

const useMostRecentMint = (leveragedToken: string): Date | null => {
  const dispatch = useDispatch();
  const contract = useSynthetixHandlerStorage();
  const { account } = useEthers();
  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    const getValue = async () => {
      if (!contract) return;
      if (!account) return;
      if (!leveragedToken) return;

      try {
        const value_ = await contract.mintedTimestamp(leveragedToken, account);
        if (value_.toNumber() === 0) return;
        const date_ = new Date(value_ * 1000);
        setDate(date_);
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching most recent mint"));
      }
    };

    getValue();
  }, [dispatch, contract, leveragedToken, account]);

  return date;
};

export default useMostRecentMint;
