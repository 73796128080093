import { ScaledNumber } from "scaled-number";
import { SUPPORTED_ASSETS } from "../constants/supported-assets";
import { ASSET_METADATA } from "../data/asset-metadata";

const assetIdToSymbol = (assetId: string) => {
  const replace = ["sETH", "sBTC", "sLINK"];
  return replace.includes(assetId) ? assetId.substring(1) : assetId;
};

export interface AssetData {
  id: string;
  icon: string;
  name: string;
  symbol: string;
  volume: number;
  coingeckoId?: string;
  color: string;
  comingSoon?: boolean;
  balance?: ScaledNumber;
  address?: string;
  price?: ScaledNumber;
}

export const getAssetData = (assetId: string) => {
  const symbol = assetIdToSymbol(assetId);
  const metadata = ASSET_METADATA[symbol];
  if (!metadata) throw new Error(`No metadata for ${assetId}`);

  const assetData: AssetData = {
    id: assetId,
    icon: `https://synthetixio.github.io/synthetix-assets/markets/${symbol}.svg`,
    name: metadata.name,
    symbol,
    volume: metadata.volume,
    coingeckoId: metadata.coingeckoId,
    color: metadata.color,
    comingSoon: !SUPPORTED_ASSETS.includes(assetId),
  };

  return assetData;
};
