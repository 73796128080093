import styled from "styled-components";
import { Header2 } from "../styles/content";

import synthetix from "../assets/supporters/synthetix.svg";
import optimism from "../assets/supporters/optimism.svg";
import chainlink from "../assets/supporters/chainlink.svg";
import pyth from "../assets/supporters/pyth.png";

const poweredBy: { name: string; link: string; image: string }[] = [
  {
    name: "Synthetix",
    link: "https://synthetix.io/",
    image: synthetix,
  },
  {
    name: "Optimism",
    link: "https://www.optimism.io/",
    image: optimism,
  },
  {
    name: "Pyth",
    link: "https://pyth.network/",
    image: pyth,
  },
  {
    name: "Chainlink",
    link: "https://chain.link/",
    image: chainlink,
  },
];

const StyledPoweredBy = styled.div`
  width: 100%;
  padding: 4rem;
  max-width: 135rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    padding: 0;
  }
`;

const Supporters = styled.div`
  width: 100%;
  display: grid;

  grid-template-columns: repeat(auto-fill, minmax(25.5rem, 1fr));
  grid-gap: 4.3rem;

  @media (max-width: 900px) {
    grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
    grid-gap: 2.6rem;
  }
`;

const Link = styled.a`
  cursor: pointer;
  border: solid 1px red;
  padding: 3.2rem;
  border-radius: 24px;
  border: 1px solid var(--fx-card-border-pink, #705d63);
  background: linear-gradient(
    180deg,
    rgba(30, 21, 25, 0.5) 0%,
    rgba(16, 16, 16, 0) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 900px) {
    padding: 2.1rem;
    height: 8.4rem;
  }

  transition: filter 0.3s ease-in-out;
  &:hover {
    filter: brightness(1.1);

    > img {
      opacity: 1;
    }
  }
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.8;

  transition: opacity 0.3s ease-in-out;
`;

const PoweredBy = () => {
  return (
    <StyledPoweredBy>
      <Header2>Powered by</Header2>
      <Supporters>
        {poweredBy.map((item, index) => (
          <Link href={item.link} key={index} target="_blank">
            <Icon src={item.image} />
          </Link>
        ))}
      </Supporters>
    </StyledPoweredBy>
  );
};

export default PoweredBy;
