import { ScaledNumber } from "scaled-number";
import useDefillamaApr from "./use-defillama-apr";

const POOL_ID = "25531ef0-9f26-4577-9574-b3c52eede8b3";

const useAmmApr = (): ScaledNumber | null => {
  return useDefillamaApr(POOL_ID, "TLX/ETH LP Rewards");
};

export default useAmmApr;
