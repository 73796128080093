import { useEthers } from "@usedapp/core";
import { Contract, ContractInterface, providers } from "ethers";
import { Interface } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { MulticallType } from "../views/use-multicall";

interface ContractWithMulti extends Contract {
  multi: any;
}

export function createMulti(address: string, abi: ContractInterface) {
  const icontract = new Interface(abi as any);

  const multi = {} as any;

  const defineEncodeProperty = (name: string) => {
    const f = (...args: any[]): MulticallType => {
      return {
        target: address,
        calldata: icontract.encodeFunctionData(name, args),
        decodeResult: (data) =>
          icontract.decodeFunctionResult(icontract.getFunction(name), data),
      };
    };
    Object.defineProperty(multi, name, {
      get() {
        return f;
      },
      enumerable: true,
    });
    const shortName = name.split("(")[0];
    if (!multi[shortName]) {
      Object.defineProperty(multi, shortName, {
        get() {
          return f;
        },
        enumerable: true,
      });
    }
  };

  for (const key of Object.keys(icontract.functions)) {
    defineEncodeProperty(key);
  }

  return multi;
}

const useContract = (address: string | null, abi: ContractInterface) => {
  const { library } = useEthers();
  const [contract, setContract] = useState<ContractWithMulti | null>(null);

  const hasLibrary = !!library;
  useEffect(() => {
    if (!library) return;
    if (!(library instanceof providers.JsonRpcProvider)) return;
    if (!address) return;

    const contract_ = new Contract(address, abi, library.getSigner()) as any;
    const multi = createMulti(address, abi);
    contract_.multi = multi;

    setContract(contract_ as ContractWithMulti);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLibrary, address, abi]);

  return contract;
};

export default useContract;
