import { getAssetData } from "./get-asset-data";

const getLeveragedTokenSymbol = (
  assetId: string,
  leverage: number,
  isLong: boolean
) => {
  const assetData = getAssetData(assetId);

  return `${assetData.symbol}${leverage}${isLong ? "L" : "S"}`;
};

export default getLeveragedTokenSymbol;
