import InfoLine from "../components/InfoLine";

import { SubCardFrame } from "../styles/Frames";

export interface InfoLineType {
  label: string;
  tooltip?: string;
  value: string;
  unit?: string;
  primary?: boolean;
  link?: string;
  error?: boolean;
  success?: boolean;
}

interface Props {
  lines: InfoLineType[];
}

const InfoSection = ({ lines }: Props) => {
  return (
    <SubCardFrame>
      {lines.map((line, index) => (
        <InfoLine
          key={index}
          label={line.label}
          tooltip={line.tooltip}
          value={line.value}
          unit={line.unit}
          primary={line.primary}
          link={line.link}
          error={line.error}
          success={line.success}
        />
      ))}
    </SubCardFrame>
  );
};

export default InfoSection;
